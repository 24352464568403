import React from "react";
import { Routes, Route } from "react-router";
import Home from "./components/pages/Home";
import Main from "./components/templates/Main";
import "./custom.css";

export default function App() {
  return (
    
          <Main>
            <Routes>
              <Route exact path="/" element={<Home />} />
            </Routes>
          </Main>
       
  );
}
