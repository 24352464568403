
export function sendEmail(datos) {
  return fetch("/api/presupuesto/send", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(datos),
  });
}
