import React, { useState } from "react";
import IngTextField from "../atomos/Ing_TextField";
import IngButton from "../atomos/Ing_Button";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import Typography from "@mui/material/Typography";
import { sendEmail } from "../../services/APIPresupuesto";
// import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Card } from "@mui/material";

const schema = yup
  .object({
    firstName: yup.string().required("The field is required"),
    surName: yup.string().required("The field is required"),
    phone: yup.string().required("The field is required"),
    message: yup.string().required("The field is required"),
    email: yup
      .string()
      .email("  Write a valid email")
      .required("The field is required"),
  })
  .required();

export default function SDForm(props) {
  // @ts-ignore

  const [uploadedFilesT, setUploadedFilesT] = useState([]);

  const [uploadedFilesB, setUploadedFilesB] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleUploadFiles = (files, arr, set) => {
    const uploaded = [...arr];
    // let limitExceeded =  false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        if (uploaded.length > 4) {
          return false;
        } else {
          uploaded.push(file);
          set(uploaded);
        }
      }
    });
  };

  const handleFileEventT = (e) => {
    console.log(e.target.files);
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    console.log(chosenFiles);

    handleUploadFiles(chosenFiles, uploadedFilesT, setUploadedFilesT);
  };
  const handleFileEventB = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles, uploadedFilesB, setUploadedFilesB);
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors, isValid }, //{errors, isValid},
  } = useForm({
    mode: "onChange", //onSubmit
    reValidateMode: "onChange",
    defaultValues: {
      firstName: "",
      surName: "",
      email: "",
      phone: "",
      message: "",
    },
    resolver: yupResolver(schema),
  });

  //peticion post
  const onClick = async () => {
    setError(false);
    if (isValid) {
      setLoading(true);
      let files = [];
      for (const f in uploadedFilesT) {
        let file = uploadedFilesT[f];
        await getBase64(file).then((r) => {
          files.push(r);
        });
      }
      let files2 = [];
      for (const f in uploadedFilesB) {
        let file2 = uploadedFilesB[f];
        await getBase64(file2).then((r) => {
          files2.push(r);
        });
      }

      let datos = {
        name: getValues("firstName"),
        surname: getValues("surName"),
        email: getValues("email"),
        phone: getValues("phone"),
        message: getValues("message"),
        style: props.style,
        size: props.size,
        bodypart: props.arm + " " + props.bodyPart,
        color: props.color,
        price: props.price,
        picturest: files,
        picturesb: files2,
      };

      sendEmail(datos)
        .then((res) => (res.ok ? res.json() : null))
        .then((json) => {
          if (json === "send") {
            setError(false);
            setLoading(false);
            props.handleNext();
            console.log("Request Ok");
            // setEnviado(true);
            // actualizar();
            // onClose();
          } else {
            setLoading(false);
            setError(true);
            console.log("Request Error");
            // onClose();
          }
        });
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result.toString();
        resolve(baseURL.split("base64,")[1]);
      };
    });
  };

  if (loading) {
    return (
      // <div className="loading">
      //   <CircularProgress />
      // </div>
      <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
        <LinearProgress color="secondary" />
      </Stack>
    );
  } else {
    return (
      <>
        {/* <Box sx={{ maxWidth: window.innerWidth<500 ? 300 : 1300 }}> */}
        {!error &&
               <div className="sdForm">
               <Controller
                 control={control}
                 name="firstName"
                 render={({ field: { onChange, value } }) => (
                   <IngTextField
                     className={window.innerWidth > 900 ? "sdTextfield" : ""}
                     label="FIRST NAME*"
                     onChange={onChange}
                     value={value}
                     errors={errors.firstName?.message}
                   />
                 )}
               />
     
               <Controller
                 control={control}
                 name="surName"
                 render={({ field: { onChange, value } }) => (
                   <IngTextField
                     className={window.innerWidth > 900 ? "sdTextfield" : ""}
                     label="SURNAME*"
                     onChange={onChange}
                     value={value}
                     errors={errors.surName?.message}
                   />
                 )}
               />
     
               <Controller
                 control={control}
                 name="email"
                 render={({ field: { onChange, value } }) => (
                   <IngTextField
                     className={window.innerWidth > 900 ? "sdTextfield" : ""}
                     label="EMAIL*"
                     onChange={onChange}
                     value={value}
                     errors={errors.email?.message}
                   />
                 )}
               />
     
               <Controller
                 control={control}
                 name="phone"
                 render={({ field: { onChange, value } }) => (
                   <IngTextField
                     className={window.innerWidth > 900 ? "sdTextfield" : ""}
                     label="PHONE*"
                     value={value}
                     onChange={onChange}
                     errors={errors.phone?.message}
                     negativo={false}
                   />
                 )}
               />
     
               <div>
                 <Button
                   style={{ width: 300, marginTop: 30 }}
                   variant="contained"
                   component="label"
                   className="botonImport"
                 >
                   TATTOO REFERENCE PICTURES
                   <input
                     multiple
                     type="file"
                     accept="application/pdf, image/png, image/jpeg"
                     hidden
                     onChange={handleFileEventT}
                   />
                 </Button>
               </div>
               {/* <div style={{display:"flex", flexWrap:"wrap", maxWidth:'300px'}}>
             {uploadedFilesT.map((file) => (
               <Card style={{margin:5, padding:4}}> {file.name}</Card>
             ))}
             </div> */}
               <Card style={{ margin: 5, padding: 4 }}>
                 <Typography variant="caption">
                   {"Uploaded " + uploadedFilesT.length + " / 5"}
                 </Typography>{" "}
               </Card>
     
               <div>
                 <Button
                   style={{ width: 300, marginTop: 10 }}
                   variant="contained"
                   component="label"
                   className="botonImport"
                 >
                   BODY PART REFERENCE PICTURES
                   <input
                     // multiple
                     type="file"
                     accept="application/pdf, image/png, image/jpeg"
                     hidden
                     onChange={handleFileEventB}
                   />
                 </Button>
               </div>
               {/* <div style={{display:"flex", flexWrap:"wrap", maxWidth:'300px'}}>
             {uploadedFilesB.map((file) => (
               <Card style={{margin:5, padding:4}}> {file.name}</Card>
             ))}
             </div> */}
               <Card style={{ margin: 5, padding: 4 }}>
                 <Typography variant="caption">
                   {"Uploaded " + uploadedFilesB.length + " / 1"}
                 </Typography>{" "}
               </Card>
               <Controller
                 control={control}
                 name="message"
                 render={({ field: { onChange, value } }) => (
                   <IngTextField
                     className={window.innerWidth > 900 ? "sdTextfield" : ""}
                     label="MESSAGE*"
                     onChange={onChange}
                     value={value}
                     multiline={true}
                     placeholder={"Is there anything else you'd like to tell us?"}
                     errors={errors.message?.message}
                   />
                 )}
               />
               </div>
        }
   
          {error && (
            <>
              <Typography variant="button">
                {"Sorry! An error has ocurred during the process"}
              </Typography><br/>
              <Typography variant="button">
                <b>
                  {"Please, go back and try to upload less quality pictures"}
                </b>
              </Typography>
            </>
          )}
        

        <Box sx={{ mb: 2 }}>
          <div className="buttonGroup">
            <IngButton
              className="sdButton"
              variant="outlined"
              label="BACK"
              color="info"
              onClick={props.handleBack}
              endIcon={<ChevronLeftOutlinedIcon />}
            />
            <IngButton
              className="sdButton"
              variant="contained"
              label="NEXT"
              color="info"
              // aqui envia correo y handle next del stepper
              // onClick={props.handleNext}
              onClick={onClick}
              endIcon={<NavigateNextOutlinedIcon />}
            />
          </div>
        </Box>
      </>
    );
  }
}
