import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import "react-perfect-scrollbar/dist/css/styles.css";
import Typography from "@mui/material/Typography";
import { Card, CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Slider from "@mui/material/Slider";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import sizesGeneral from "../../helpers/sizesGeneral.json";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import IngButton from "../atomos/Ing_Button";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import pricesGeneral from "../../helpers/pricesGeneral.json";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
//Carrusel
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import SDForm from "./SDForm";

// @ts-ignore
// export const Transition = React.forwardRef((props, ref) => (
//   <Grow {...props} ref={ref} />
// ));

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reaImg = [
  {
    label: "1",
    imgPath: "../img/carrousel/REA/REA1.jpg",
  },
  {
    label: "2",
    imgPath: "../img/carrousel/REA/REA2.jpg",
  },
  {
    label: "3",
    imgPath: "../img/carrousel/REA/REA3.jpg",
  },
  {
    label: "4",
    imgPath: "../img/carrousel/REA/REA4.jpg",
  },
  {
    label: "5",
    imgPath: "../img/carrousel/REA/REA5.jpg",
  },
];

export default function Home() {
  //customer selection states
  const [style, setStyle] = useState("");
  const [color, setColor] = useState("");
  const [styleLabel, setStyleLabel] = useState("");
  const [gender, setGender] = useState("");
  const [bodyPart, setBodyPart] = useState("");
  const [subZone, setSubzone] = useState("");
  const [subZoneSlider, setSubzoneSlider] = useState(0);
  const [subZoneLabel, setSubzoneLabel] = useState("");
  const [size, setSize] = useState();
  const [sizeLabel, setSizeLabel] = useState("");
  const [price, setPrice] = useState("");
  const [loading, setLoading] = useState(true);
  // const [subZoneMod, setSubZoneMod] = useState("slider");
  const [arm, setArm] = useState("");
  const [side, setSide] = useState("");
  const [turn, setTurn] = useState(false);
  //Form

  //BodyPartsFunctions
  const ChestClick = (e) => {
    setBodyPart("CHEST");
    handleNext();
    e.preventDefault();
  };
  const BackClick = (e) => {
    setBodyPart("BACK");
    handleNext();
    e.preventDefault();
  };
  const ArmLClick = (e) => {
    setBodyPart("ARM");
    setArm("LEFT");
    handleNext();
    e.preventDefault();
  };
  const ArmRClick = (e) => {
    setBodyPart("ARM");
    setArm("RIGHT");
    handleNext();
    e.preventDefault();
  };
  const HeadNeckClick = (e) => {
    setBodyPart("HEAD");
    handleNext();
    e.preventDefault();
  };
  const LegsClick = (e) => {
    setBodyPart("LEGS");
    handleNext();
    e.preventDefault();
  };
  const GluteusClick = (e) => {
    setBodyPart("GLUTEUS");
    handleNext();
    e.preventDefault();
  };

  //SUBZONES SLIDER
  const chestParts = [
    {
      value: 0,
      label: "FULL FRONT",
    },
    {
      value: 12,
      label: "RIGHT CHEST",
    },
    {
      value: 24,
      label: "MID CHEST",
    },
    {
      value: 36,
      label: "LEFT CHEST",
    },
    {
      value: 48,
      label: "STOMACH",
    },
    {
      value: 62,
      label: "FULL CHEST",
    },
    {
      value: 75,
      label: "RIGHT RIBS",
    },
    {
      value: 87,
      label: "LEFT RIBS",
    },
    {
      value: 100,
      label: "UNDER BOOBS",
    },
  ];
  const getChestZone = (num) => {
    if (num === 0) {
      return "FULLFRONT";
    } else if (num === 12) {
      return "CHESTR";
    } else if (num === 24) {
      return "CHESTM";
    } else if (num === 36) {
      return "CHESTL";
    } else if (num === 48) {
      return "STOMACH";
    } else if (num === 62) {
      return "FULLCHEST";
    } else if (num === 75) {
      return "RIBSR";
    } else if (num === 87) {
      return "RIBSL";
    } else if (num === 100) {
      return "UNDERBOOBS";
    }
  };
  const handleChangeChest = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getChestZone(newValue));
      // setSubzoneLabel(getChestZone(newValue));
    }
  };
  const headParts = [
    {
      value: 0,
      label: "FULL HEAD",
    },
    {
      value: 12,
      label: "HEAD-RIGHT",
    },
    {
      value: 25,
      label: "HEAD-LEFT",
    },
    {
      value: 37,
      label: "TOP HEAD",
    },
    {
      value: 50,
      label: "FULL NECK",
    },
    {
      value: 62,
      label: "NECK-RIGHT",
    },
    {
      value: 75,
      label: "NECK-LEFT",
    },
    {
      value: 87,
      label: "NECK-BACK",
    },
    {
      value: 100,
      label: "THROAT",
    },
  ];
  const getHeadZone = (num) => {
    if (num === 0) {
      return "FULLHEAD";
    } else if (num === 12) {
      return "HEADR";
    } else if (num === 25) {
      return "HEADL";
    } else if (num === 37) {
      return "HEADTOP";
    } else if (num === 50) {
      return "FULLNECK";
    } else if (num === 62) {
      return "NECKR";
    } else if (num === 75) {
      return "NECKL";
    } else if (num === 87) {
      return "NECKB";
    } else if (num === 100) {
      return "THROAT";
    }
  };
  const handleChangeHead = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getHeadZone(newValue));
      // setSubzoneLabel(getHeadZone(newValue));
    }
  };
  const backParts = [
    {
      value: 0,
      label: "FULL BACK",
    },
    {
      value: 25,
      label: "SCAPULA-R",
    },
    {
      value: 50,
      label: "SCAPULA-L",
    },
    {
      value: 75,
      label: "LOWER BACK",
    },
    {
      value: 100,
      label: "UPPER BACK",
    },
  ];
  const getBackZone = (num) => {
    if (num === 0) {
      return "FULLBACK";
    } else if (num === 25) {
      return "SCAPR";
    } else if (num === 50) {
      return "SCAPL";
    } else if (num === 75) {
      return "LOWBACK";
    } else if (num === 100) {
      return "UPPBACK";
    }
  };
  const handleChangeBack = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getBackZone(newValue));
      // setSubzoneLabel(getBackZone(newValue));
    }
  };
  const armParts = [
    {
      value: 0,
      label: "FULL ARM",
    },
    {
      value: 16,
      label: "UPPER ARM",
    },
    {
      value: 33,
      label: "BICEPS",
    },
    {
      value: 49,
      label: "INNER FOREARM",
    },
    {
      value: 66,
      label: "OUTER FOREARM",
    },
    {
      value: 83,
      label: "ELBOW",
    },
    {
      value: 100,
      label: "FULLHAND",
    },
  ];
  const getArmZone = (num) => {
    if (num === 0) {
      return "FULLARM";
    } else if (num === 16) {
      return "UPPERARM";
    } else if (num === 33) {
      return "BICEPS";
    } else if (num === 49) {
      return "INNERFOREARM";
    } else if (num === 66) {
      return "OUTERFOREARM";
    } else if (num === 83) {
      return "ELBOW";
    } else if (num === 100) {
      return "FULLHAND";
    }
  };
  const handleChangeArm = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getArmZone(newValue));
      // setSubzoneLabel(getArmZone(newValue));
    }
  };
  const gluteusParts = [
    {
      value: 0,
      label: "FULL",
    },
    {
      value: 50,
      label: "RIGHT",
    },
    {
      value: 100,
      label: "LEFT",
    },
  ];
  const getGluteusZone = (num) => {
    if (num === 0) {
      return "FULLGLUTEUS";
    } else if (num === 50) {
      return "GLUTEUSR";
    } else if (num === 100) {
      return "GLUTEUSL";
    }
  };
  const handleChangeGluteus = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getGluteusZone(newValue));
      // setSubzoneLabel(getGluteusZone(newValue));
    }
  };
  const legParts = [
    {
      value: 0,
      label: "FULL LEG",
    },
    {
      value: 14,
      label: "HALF LEG OUTSIDE",
    },
    {
      value: 28,
      label: "THIG/SIDE THIGS",
    },
    {
      value: 42,
      label: "HAMSTRING",
    },
    {
      value: 56,
      label: "KNEE",
    },
    {
      value: 70,
      label: "CALF/SIDE CALF",
    },
    {
      value: 85,
      label: "SHIN",
    },
    {
      value: 100,
      label: "FULL FOOT",
    },
  ];
  const getLegZone = (num) => {
    if (num === 0) {
      return "FULLLEG";
    } else if (num === 14) {
      return "HALFLEGOUT";
    } else if (num === 28) {
      return "THIGHS";
    } else if (num === 42) {
      return "HAMSTRING";
    } else if (num === 56) {
      return "KNEE";
    } else if (num === 70) {
      return "CALFS";
    } else if (num === 85) {
      return "SHIN";
    } else if (num === 100) {
      return "FOOT";
    }
  };
  const handleChangeLeg = (event, newValue) => {
    if (typeof newValue === "number") {
      setSubzoneSlider(newValue);
      setSubzone(getLegZone(newValue));
      // setSubzoneLabel(getLegZone(newValue));
    }
  };
  const getSubZoneName = (code) => {
    if (code === "FULLFRONT") {
      return "Full Front";
    } else if (code === "CHESTR") {
      return "Right Chest";
    } else if (code === "CHESTM") {
      return "Middle Chest";
    } else if (code === "CHESTL") {
      return "Left Chest";
    } else if (code === "STOMACH") {
      return "Stomach";
    } else if (code === "FULLCHEST") {
      return "Full Chest";
    } else if (code === "RIBSR") {
      return "Right Ribs";
    } else if (code === "RIBSL") {
      return "Left Ribs";
    } else if (code === "UNDERBOOBS") {
      return "Under Boobs";
    } else if (code === "FULLHEAD") {
      return "Full Head";
    } else if (code === "HEADR") {
      return "Head Right Side";
    } else if (code === "HEADL") {
      return "Head Left Side";
    } else if (code === "HEADTOP") {
      return "Head Top";
    } else if (code === "FULLNECK") {
      return "Full Neck";
    } else if (code === "NECKR") {
      return "Neck Right Side";
    } else if (code === "NECKL") {
      return "Neck Left Side";
    } else if (code === "NECKB") {
      return "Neck Back";
    } else if (code === "THROAT") {
      return "Throat";
    } else if (code === "FULLBACK") {
      return "Full Back";
    } else if (code === "SCAPR") {
      return "Right Scapula";
    } else if (code === "SCAPL") {
      return "Left Scapula";
    } else if (code === "LOWBACK") {
      return "Lower Back ";
    } else if (code === "UPPBACK") {
      return "Upper Back";
    } else if (code === "FULLARM") {
      return "Full Arm";
    } else if (code === "UPPERARM") {
      return "Upper Arm";
    } else if (code === "BICEPS") {
      return "Biceps";
    } else if (code === "INNERFOREARM") {
      return "Inner Forearm";
    } else if (code === "OUTERFOREARM") {
      return "Outer Forearm";
    } else if (code === "ELBOW") {
      return "Elbow";
    } else if (code === "FULLHAND") {
      return "Full Hand";
    } else if (code === "FULLGLUTEUS") {
      return "Full Gluteus";
    } else if (code === "GLUTEUSR") {
      return "Right Gluteus";
    } else if (code === "GLUTEUSL") {
      return "Left Gluteus";
    } else if (code === "FULLLEG") {
      return "Full Leg";
    } else if (code === "HALFLEGOUT") {
      return "Half Leg Outside";
    } else if (code === "THIGHS") {
      return "Thigh/Side Thighs";
    } else if (code === "HAMSTRING") {
      return "Hamstring";
    } else if (code === "KNEE") {
      return "Knee";
    } else if (code === "CALFS") {
      return "Calf/Side Calf";
    } else if (code === "SHIN") {
      return "Shin";
    } else if (code === "FOOT") {
      return "Full Foot";
    }
  };
  //Sizes

  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setSize(newValue);
    }
  };

  const getSize = (num) => {
    if (num === 1) {
      return "XS";
    } else if (num === 20) {
      return "S";
    } else if (num === 40) {
      return "M";
    } else if (num === 60) {
      return "L";
    } else if (num === 80) {
      return "XL";
    } else if (num === 100) {
      return "XXL";
    }
  };
  //Stepper
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextPrice = () => {
    // setTimeout(setLoading(false), 2500);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    setSizeLabel(getSize(size));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    let code = "";
    if (style === "NOTSURE") {
      setPrice(
        "Sorry! We don't have a price for your selection. Contact us for more info!"
      );
    } else {
      code = style + subZone + getSize(size);
      setPrice(pricesGeneral[code]);
      console.log(code);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  //Fin Stepper

  //Carrousel
  const theme = useTheme();
  const [activeStepC, setActiveStepC] = useState(0);
  const maxSteps = reaImg.length;

  const handleNextC = () => {
    setActiveStepC((prevActiveStepC) => prevActiveStepC + 1);
  };

  const handleBackC = () => {
    setActiveStepC((prevActiveStepC) => prevActiveStepC - 1);
  };

  const handleStepChange = (step) => {
    setActiveStepC(step);
  };
  //fin carrousel

  return (
    <div className="sdPage">
      <div className="sdNavbar">
        {/* <div className="sdLogo" /> */}
        <img className="sdLogo2" alt="" src={require("../img/sdLogo.jpg")} />
      </div>
      {/* <PerfectScrollbar> */}
      <div className="sdContent">
        <div className="sdSteps">
          <hr />
          <h5 className="labelPreStep">
            <b>FOLLOW THE STEPS TO GET YOUR QUOTE </b>
          </h5>
          <hr />

          <Box sx={{ maxWidth: 1300 }}>
            {/* <Box> */}
            <Stepper activeStep={activeStep} orientation="vertical">
              {/*STEP 1------------------------------COLOR-----------------------------------------*/}
              <Step>
                <StepLabel
                  optional={
                    color ? (
                      <Typography variant="caption">{color}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>SELECT COLOUR</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setColor("FULL COLOUR");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleColor1">
                          <div className="labelStep">
                            <h6>
                              <b>COLOUR</b>
                            </h6>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setColor("BLACK AND GREY");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleColor3">
                          <Typography variant="caption">
                            <div className="labelStep">
                              <h6>
                                <b>BLACK AND GREY</b>
                              </h6>
                            </div>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setColor("PARTIAL COLOUR");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleColor2">
                          <div className="labelStep">
                            <h6>
                              <b>B&G PARTIAL COLOUR</b>
                            </h6>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                </StepContent>
              </Step>

              {/*STEP 2 ---------------------------STYLE---------------------------------*/}
              <Step>
                <StepLabel
                  optional={
                    style ? (
                      <Typography variant="caption">{styleLabel}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>SELECT STYLE</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("REA");
                          setStyleLabel("REALISM");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleRea"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("POR");
                          setStyleLabel("PORTRAIT");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStylePor"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("PET");
                          setStyleLabel("PET PORTRAIT");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStylePet"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("FIN");
                          setStyleLabel("FINE LINE");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleFin"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("LET");
                          setStyleLabel("SCRIPT / LETTERING");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleLet"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("GEO");
                          setStyleLabel("BLACKWORK / GEOMETRY");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleGeo"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("TRA");
                          setStyleLabel("TRADITIONAL");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleTra"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("NEO");
                          setStyleLabel("NEOTRADITIONAL / ANIME");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleNeo"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("JAP");
                          setStyleLabel("JAPANESE");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleJap"></CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setStyle("NOTSURE");
                          setStyleLabel("I'M NOT SURE");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardStyleNot">
                          <div className="labelStep">
                            <h1>I'M NOT SURE</h1>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="outlined"
                        label="BACK"
                        color="info"
                        onClick={() => {
                          setStyle("");
                          setColor("");
                          handleBack();
                        }}
                        endIcon={<ChevronLeftOutlinedIcon />}
                      />
                    </div>
                  </Box>
                </StepContent>
              </Step>

              {/*STEP 3------------------GENDER------------------*/}
              <Step>
                <StepLabel
                  optional={
                    gender ? (
                      <Typography variant="caption">{gender}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>GENDER</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setGender("MAN");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardGender1">
                          <div className="labelStep">
                            <h6>MALE</h6>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                    <Card className="sdCard">
                      <CardActionArea
                        onClick={() => {
                          setGender("WOMAN");
                          handleNext();
                        }}
                      >
                        <CardContent className="cardGender2">
                          <div className="labelStep">
                            <h6>FEMALE</h6>
                          </div>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="outlined"
                        label="BACK"
                        color="info"
                        onClick={() => {
                          setGender("");
                          setStyle("");
                          handleBack();
                        }}
                        endIcon={<ChevronLeftOutlinedIcon />}
                      />
                    </div>
                  </Box>
                </StepContent>
              </Step>
              {/*STEP 4-------------BODY PART------------*/}
              <Step>
                <StepLabel
                  optional={
                    bodyPart ? (
                      <Typography variant="caption">{bodyPart}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>SELECT BODY PART</b>
                    </h5>
                    {!bodyPart && (
                      <Typography variant="caption">
                        PRESS ON THE BODY
                      </Typography>
                    )}
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="buttonGroup">
                    <IngButton
                      className="sdButton"
                      variant="outlined"
                      label="TURN"
                      color="info"
                      onClick={() => {
                        setTurn(!turn);
                      }}
                      endIcon={<RefreshOutlinedIcon />}
                    />
                  </div>
                  <div className="stepOne">
                    {/* -------Front MALE------- */}
                    {gender === "MAN" && turn === false && (
                      <>
                        <img
                          src={require("../img/manBodyFront.jpg")}
                          alt="Workplace"
                          useMap="#workmap"
                          height="450"
                        />

                        <map name="workmap">
                          <area
                            id="chestMap"
                            shape="rect"
                            coords="65,83 145,210"
                            alt="Computer"
                            href=""
                            onClick={ChestClick}
                          />
                          <area
                            id="leftArmMap"
                            shape="rect"
                            coords="22,83 65,260"
                            alt="Computer"
                            href=""
                            onClick={ArmLClick}
                          />
                          <area
                            id="rightArmMap"
                            shape="rect"
                            coords="195,83 145,260"
                            alt="Computer"
                            href=""
                            onClick={ArmRClick}
                          />
                          <area
                            id="headNeckMap"
                            shape="rect"
                            coords="65,10 145,83"
                            alt="Computer"
                            href=""
                            onClick={HeadNeckClick}
                          />
                          <area
                            id="legsMap"
                            shape="rect"
                            coords="61,210 149,440"
                            alt="Computer"
                            href=""
                            onClick={LegsClick}
                          />
                        </map>
                      </>
                    )}
                    {/* -------Back Male------- */}
                    {gender === "MAN" && turn === true && (
                      <>
                        <img
                          src={require("../img/manBodyBack.jpg")}
                          alt="Workplace"
                          useMap="#workmap"
                          height="450"
                        />

                        <map name="workmap">
                          <area
                            id="backMap"
                            shape="rect"
                            coords="63,77 145,195"
                            alt="Computer"
                            href=""
                            onClick={BackClick}
                          />
                          <area
                            id="leftArmMap"
                            shape="rect"
                            coords="14,82 62,260"
                            alt="Computer"
                            href=""
                            onClick={ArmLClick}
                          />
                          <area
                            id="rightArmMap"
                            shape="rect"
                            coords="195,82 146,260"
                            alt="Computer"
                            href=""
                            onClick={ArmRClick}
                          />
                          <area
                            id="headNeckMap"
                            shape="rect"
                            coords="65,10 145,76"
                            alt="Computer"
                            href=""
                            onClick={HeadNeckClick}
                          />
                          <area
                            id="legsMap"
                            shape="rect"
                            coords="61,240 149,440"
                            alt="Computer"
                            href=""
                            onClick={LegsClick}
                          />
                          <area
                            id="legsMap"
                            shape="rect"
                            coords="61,196 149,240"
                            alt="Computer"
                            href=""
                            onClick={GluteusClick}
                          />
                        </map>
                      </>
                    )}
                    {/* ---------WOMAN Front--------- */}
                    {gender === "WOMAN" && turn === false && (
                      <>
                        <img
                          src={require("../img/womanBodyFront.jpg")}
                          alt="Workplace"
                          useMap="#workmap"
                          height="450"
                        />

                        <map name="workmap">
                          <area
                            id="chestMap"
                            shape="rect"
                            coords="76,75 148,212"
                            alt="Computer"
                            href=""
                            onClick={ChestClick}
                          />
                          <area
                            id="leftArmMap"
                            shape="rect"
                            coords="20,73 75,260"
                            alt="Computer"
                            href=""
                            onClick={ArmLClick}
                          />
                          <area
                            id="rightArmMap"
                            shape="rect"
                            coords="149,73 211,260"
                            alt="Computer"
                            href=""
                            onClick={ArmRClick}
                          />
                          <area
                            id="headNeckMap"
                            shape="rect"
                            coords="70,6 150,73"
                            alt="Computer"
                            href=""
                            onClick={HeadNeckClick}
                          />
                          <area
                            id="legsMap"
                            shape="rect"
                            coords="76,210 148,442"
                            alt="Computer"
                            href=""
                            onClick={LegsClick}
                          />
                        </map>
                      </>
                    )}
                    {/* ---------WOMAN Back-------------- */}
                    {gender === "WOMAN" && turn === true && (
                      <>
                        <img
                          src={require("../img/womanBodyBack.jpg")}
                          alt="Workplace"
                          useMap="#workmap"
                          height="450"
                        />

                        <map name="workmap">
                          <area
                            id="backMap"
                            shape="rect"
                            coords="96,80 169,195"
                            alt="Computer"
                            href=""
                            onClick={BackClick}
                          />
                          <area
                            id="leftArmMap"
                            shape="rect"
                            coords="40,80 95,245"
                            alt="Computer"
                            href=""
                            onClick={ArmLClick}
                          />
                          <area
                            id="rightArmMap"
                            shape="rect"
                            coords="170,80 225,245"
                            alt="Computer"
                            href=""
                            onClick={ArmRClick}
                          />
                          <area
                            id="headNeckMap"
                            shape="rect"
                            coords="95,10 170,79"
                            alt="Computer"
                            href=""
                            onClick={HeadNeckClick}
                          />
                          <area
                            id="gluteusMap"
                            shape="rect"
                            coords="96,196 169,243"
                            alt="Computer"
                            href=""
                            onClick={GluteusClick}
                          />
                          <area
                            id="legsMap"
                            shape="rect"
                            coords="93,244 172,442"
                            alt="Computer"
                            href=""
                            onClick={LegsClick}
                          />
                        </map>
                      </>
                    )}
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="outlined"
                        label="BACK"
                        color="info"
                        onClick={() => {
                          setArm("");
                          setBodyPart("");
                          setGender("");
                          handleBack();
                        }}
                        endIcon={<ChevronLeftOutlinedIcon />}
                      />
                    </div>
                  </Box>
                </StepContent>
              </Step>
              {/*STEP 5-------------------SELECT ZONE-------------------*/}
              <Step>
                <StepLabel
                  optional={
                    bodyPart ? (
                      <Typography variant="caption">{subZoneLabel}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>SELECT ZONE</b>
                    </h5>
                    {!subZone && (
                      <Typography variant="caption">USE THE SLIDER</Typography>
                    )}
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {bodyPart === "ARM" || bodyPart === "LEGS" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: 30,
                          }}
                        >
                          {!side && (
                            <Typography variant="caption">
                              WHICH {bodyPart === "ARM" ? " ARM" : " LEG"}?
                            </Typography>
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 5,
                            }}
                          >
                            <IngButton
                              className="sdButtonSide"
                              variant={
                                side === "LEFT" ? "contained" : "outlined"
                              }
                              label="LEFT"
                              color="info"
                              onClick={() => {
                                setSide("LEFT");
                              }}
                              endIcon={<ArrowCircleLeftOutlinedIcon />}
                            />
                            <IngButton
                              className="sdButtonSide"
                              variant={
                                side === "RIGHT" ? "contained" : "outlined"
                              }
                              label="RIGHT"
                              color="info"
                              onClick={() => {
                                setSide("RIGHT");
                              }}
                              endIcon={<ArrowCircleRightOutlinedIcon />}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      {(bodyPart === "ARM" || bodyPart === "LEGS") && side ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {!subZone && bodyPart && gender && (
                            <img
                              src={require("../img/base/" +
                                gender +
                                bodyPart +
                                ".jpg")}
                              alt="Workplace"
                              useMap="#workmap"
                              height="240"
                              style={{ marginTop: 20, marginRight: 0 }}
                            />
                          )}
                          {subZone && (
                            <img
                              src={require("../img/border/" +
                                gender +
                                subZone +
                                "BORDER.jpg")}
                              alt="Workplace"
                              height="240"
                              style={{ marginTop: 20, marginRight: 0 }}
                            />
                          )}
                          <Slider
                            size="small"
                            orientation="vertical"
                            // valueLabelDisplay="on"
                            // valueLabelFormat={getChestZone}
                            defaultValue={0}
                            value={subZoneSlider}
                            onChange={
                              bodyPart === "CHEST"
                                ? handleChangeChest
                                : bodyPart === "HEAD"
                                ? handleChangeHead
                                : bodyPart === "ARM"
                                ? handleChangeArm
                                : bodyPart === "LEGS"
                                ? handleChangeLeg
                                : bodyPart === "GLUTEUS"
                                ? handleChangeGluteus
                                : bodyPart === "BACK"
                                ? handleChangeBack
                                : undefined
                            }
                            step={null}
                            marks={
                              bodyPart === "CHEST"
                                ? chestParts
                                : bodyPart === "HEAD"
                                ? headParts
                                : bodyPart === "ARM"
                                ? armParts
                                : bodyPart === "LEGS"
                                ? legParts
                                : bodyPart === "GLUTEUS"
                                ? gluteusParts
                                : bodyPart === "BACK"
                                ? backParts
                                : undefined
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      {bodyPart !== "ARM" && bodyPart !== "LEGS" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {!subZone && bodyPart && gender && (
                            <img
                              src={require("../img/base/" +
                                gender +
                                bodyPart +
                                ".jpg")}
                              alt="Workplace"
                              useMap="#workmap"
                              height="240"
                              style={{ marginTop: 20, marginRight: 0 }}
                            />
                          )}
                          {subZone && (
                            <img
                              src={require("../img/border/" +
                                gender +
                                subZone +
                                "BORDER.jpg")}
                              alt="Workplace"
                              height="240"
                              style={{ marginTop: 20, marginRight: 0 }}
                            />
                          )}
                          <Slider
                            size="small"
                            orientation="vertical"
                            // valueLabelDisplay="on"
                            // valueLabelFormat={getChestZone}
                            defaultValue={0}
                            value={subZoneSlider}
                            onChange={
                              bodyPart === "CHEST"
                                ? handleChangeChest
                                : bodyPart === "HEAD"
                                ? handleChangeHead
                                : bodyPart === "ARM"
                                ? handleChangeArm
                                : bodyPart === "LEGS"
                                ? handleChangeLeg
                                : bodyPart === "GLUTEUS"
                                ? handleChangeGluteus
                                : bodyPart === "BACK"
                                ? handleChangeBack
                                : undefined
                            }
                            step={null}
                            marks={
                              bodyPart === "CHEST"
                                ? chestParts
                                : bodyPart === "HEAD"
                                ? headParts
                                : bodyPart === "ARM"
                                ? armParts
                                : bodyPart === "LEGS"
                                ? legParts
                                : bodyPart === "GLUTEUS"
                                ? gluteusParts
                                : bodyPart === "BACK"
                                ? backParts
                                : undefined
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="outlined"
                        label="BACK"
                        color="info"
                        onClick={() => {
                          setSubzoneLabel("");
                          setSubzoneSlider(0);
                          setSubzone("");
                          setBodyPart("");
                          setArm("");
                          setSide("");
                          handleBack();
                        }}
                        endIcon={<ChevronLeftOutlinedIcon />}
                      />
                      {subZone && (
                        <IngButton
                          className="sdButton"
                          variant="contained"
                          label="NEXT"
                          color="info"
                          onClick={() => {
                            setSize(sizesGeneral[subZone].default);
                            setLoading(true);
                            handleNext();
                            setSubzoneLabel(getSubZoneName(subZone));
                          }}
                          endIcon={<NavigateNextOutlinedIcon />}
                        />
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
              {/*STEP 6------------------SELECT SIZE--------------------*/}
              <Step>
                <StepLabel
                  optional={
                    sizeLabel ? (
                      <Typography variant="caption">{sizeLabel}</Typography>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div className="labelStep">
                    <h5>
                      <b>SELECT SIZE</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Slider
                        size="small"
                        aria-label="Custom marks"
                        // defaultValue={1}
                        defaultValue={size}
                        value={size}
                        onChange={handleChange}
                        step={null}
                        // marks={sizesGeneral[subZone]}
                        marks={subZone && size && sizesGeneral[subZone].sizes}
                      />
                      <img
                        src={
                          subZone &&
                          size &&
                          require("../img/size/" +
                            gender +
                            subZone +
                            getSize(size) +
                            ".jpg")
                          // : require("../img/manChest.png")
                        }
                        alt="Workplace"
                        useMap="#workmap"
                        height="300"
                        style={{ marginTop: 20 }}
                      />
                    </div>
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="outlined"
                        label="BACK"
                        color="info"
                        onClick={() => {
                          setSize(0);
                          setSizeLabel("");
                          setSubzoneLabel("");
                          handleBack();
                        }}
                        endIcon={<ChevronLeftOutlinedIcon />}
                      />
                      <IngButton
                        className="sdButton"
                        variant="contained"
                        label="NEXT"
                        color="info"
                        onClick={() => {
                          setLoading(true);
                          handleNextPrice();
                        }}
                        endIcon={<NavigateNextOutlinedIcon />}
                      />
                    </div>
                  </Box>
                </StepContent>
              </Step>
              {/*STEP 7------------------PRICE--------------------*/}
              <Step>
                <StepLabel
                // optional={
                //   <Typography variant="caption">STYLE RELATED TATTOOS</Typography>
                // }
                >
                  <div className="labelStep">
                    <h5>
                      <b>YOUR PRICE</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent
                // TransitionComponent={Transition}
                // transitionDuration={400}
                >
                  {loading ? (
                    <Stack
                      sx={{ width: "100%", color: "grey.500" }}
                      spacing={2}
                    >
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                      <LinearProgress color="secondary" />
                    </Stack>
                  ) : (
                    <>
                      <div className="stepOne">
                        {/* carrousel */}
                        <Box
                          sx={{
                            display: "flex",
                            maxWidth: 400,
                            flexGrow: 1,
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          {style && (
                            <div>
                              <Typography variant="caption">
                                <b>STYLE RELATED TATTOOS</b>
                              </Typography>
                              <AutoPlaySwipeableViews
                                axis={
                                  theme.direction === "rtl" ? "x-reverse" : "x"
                                }
                                index={activeStepC}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                              >
                                {reaImg.map((step, index) => (
                                  <div key={step.label}>
                                    {Math.abs(activeStepC - index) <= 2 ? (
                                      // <Box
                                      //   component="img"
                                      //   sx={{
                                      //     // height: 255,
                                      //     display: "block",
                                      //     maxWidth: 400,
                                      //     overflow: "hidden",
                                      //     width: "100%",
                                      //   }}

                                      //   src={require(step.imgPath)}
                                      //   alt={step.label}
                                      // />
                                      <img
                                        // src={require("../img/carrousel/REA/REA1.jpg")}
                                        //              '../img/carrousel/REA/REA1.jpg'
                                        src={require("../img/carrousel/" +
                                          style +
                                          "/" +
                                          style +
                                          index +
                                          ".jpg")}
                                        alt={step.label}
                                        // useMap="#workmap"
                                        // height="300"
                                        style={{
                                          height: 300,
                                          marginTop: 20,
                                          maxWidth: 400,
                                          width: "100%",
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                ))}
                              </AutoPlaySwipeableViews>
                              <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStepC}
                                nextButton={
                                  <Button
                                    size="small"
                                    onClick={handleNextC}
                                    disabled={activeStepC === maxSteps - 1}
                                  >
                                    {theme.direction === "rtl" ? (
                                      <KeyboardArrowLeft />
                                    ) : (
                                      <KeyboardArrowRight />
                                    )}
                                  </Button>
                                }
                                backButton={
                                  <Button
                                    size="small"
                                    onClick={handleBackC}
                                    disabled={activeStepC === 0}
                                  >
                                    {theme.direction === "rtl" ? (
                                      <KeyboardArrowRight />
                                    ) : (
                                      <KeyboardArrowLeft />
                                    )}
                                  </Button>
                                }
                              />
                            </div>
                          )}

                          {/* fin carrousel */}
                          {price ? (
                            <span className="priceStep">
                              Your tattoo price bracket is approx:
                            </span>
                          ) : (
                            <></>
                          )}
                          {price ? (
                            <Typography variant="caption">{price}</Typography>
                          ) : (
                            <Typography variant="caption">
                              {
                                "Sorry! We don't have a price for your selection. Contact us for more info!"
                              }
                            </Typography>
                          )}
                        </Box>
                      </div>
                      <Box sx={{ mb: 2 }}>
                        <div className="buttonGroup">
                          <IngButton
                            className="sdButton"
                            variant="outlined"
                            label="BACK"
                            color="info"
                            onClick={handleBack}
                            endIcon={<ChevronLeftOutlinedIcon />}
                          />
                          <IngButton
                            className="sdButton"
                            variant="contained"
                            label="NEXT"
                            color="info"
                            onClick={handleNext}
                            endIcon={<NavigateNextOutlinedIcon />}
                          />
                        </div>
                      </Box>
                    </>
                  )}
                </StepContent>
              </Step>
              {/*STEP 8-------------------CONTACT US-----------------*/}
              <Step>
                <StepLabel
                // optional={
                //   <Typography variant="caption">Last step</Typography>
                // }
                >
                  <div className="labelStep">
                    <h5>
                      <b>CONTACT US</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <SDForm
                    style={styleLabel}
                    size={sizeLabel}
                    bodyPart={subZoneLabel}
                    arm={side}
                    color={color}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    price={price}
                  />
                </StepContent>
              </Step>
              {/*STEP 9-----------------------THANK YOU------------------*/}
              <Step>
                <StepLabel
                // optional={
                //   <Typography variant="caption">Last step</Typography>
                // }
                >
                  <div className="labelStep">
                    <h5>
                      <b>THANK YOU</b>
                    </h5>
                  </div>
                </StepLabel>
                <StepContent>
                  <div className="stepOne">
                    {/* <Typography>Thank you! We will contact you soon!</Typography> */}
                    <div className="thankStep">
                      <h5>Thank you!</h5>
                      <h5>We will contact you soon!</h5>
                      <div>
                        <FavoriteOutlinedIcon
                          className="heartColor"
                          fontSize="large"
                        />
                        <FavoriteOutlinedIcon
                          className="heartColor"
                          fontSize="large"
                        />
                        <FavoriteOutlinedIcon
                          className="heartColor"
                          fontSize="large"
                        />
                      </div>
                    </div>
                  </div>
                  <Box sx={{ mb: 2 }}>
                    <div className="buttonGroup">
                      <IngButton
                        className="sdButton"
                        variant="contained"
                        label="OK"
                        color="info"
                        onClick={() =>
                          window.location.replace("https://smileydogg.com/")
                        }
                        endIcon={<NavigateNextOutlinedIcon />}
                      />
                    </div>
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
            {/* {activeStep === 1 && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )} */}
          </Box>
        </div>
      </div>
      {/* </PerfectScrollbar> */}
    </div>
  );
}
