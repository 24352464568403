import React, { useState, useContext } from "react";
import "./main.scss";
import "./main.css";
import { useNavigate } from "react-router-dom";
//Iconos


import AppContext from "../../contexts/AppContext";

const Main = ({ children }) => {
  //Context
  // @ts-ignore
  const { global } = useContext(AppContext);

  const [menuCollapse, setMenuCollapse] = useState(true);
  const navigate = useNavigate();

  return (
    <div id="page">
      <main id="main">
        <div id="appContent">{children}</div>
      </main>
    </div>
  );
};
export default Main;
